<template>
  <section>
    <div>
      <v-row class="mb-5 text-center" v-if="grade === 'collaborateur'">
        <v-col class="" cols="12" md="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card link v-bind="attrs" v-on="on" outlined @click="tab = 'tab-1'" class="rounded-xl" elevation="3">
                <loading
                  transition="fade"
                  :active.sync="waitTimeout"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <loading
                  transition="fade"
                  :active.sync="waitTimeoutContact"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <v-card-title>Demande de rappels en attente</v-card-title>
                <v-card-text v-if="contact.length > 0">
                  <v-icon size="48" color="EoleYellow">mdi-minus-thick</v-icon>
                  <h2>{{ contact.length }}</h2>
                </v-card-text>
                <v-card-text v-else>
                  <p style="font-size: 3rem">🎉</p>
                  <p>Bonne nouvelle, vous n'avez aucune demande de rappel en attente !</p>
                  <p>On peut dire que vous êtes plutôt efficace 😉</p>
                </v-card-text>
              </v-card>
            </template>
            <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                class="flex d-flex flex-column rounded-xl"
                link
                v-bind="attrs"
                v-on="on"
                outlined
                @click="tab = 'tab-2'"
                elevation="3"
              >
                <loading
                  transition="fade"
                  :active.sync="waitTimeout"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <loading
                  transition="fade"
                  :active.sync="waitTimeoutContact"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <v-card-title>Demande de rappels traités</v-card-title>

                <v-row v-if="contactTreated.length > 0" align="center">
                  <v-card-text>
                    <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon>
                    <h2>{{ contactTreated.length }}</h2>
                  </v-card-text>
                </v-row>
                <v-row v-else align="center">
                  <v-card-text>
                    <p>Il n'y a pour le moment pas d'historique.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Les appels traités sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="mb-5 text-center my-5 mx-5" v-else>
        <v-row> </v-row>
        <v-col class="d-flex" cols="12" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-xl" link v-bind="attrs" v-on="on" outlined elevation="3">
                <loading
                  transition="fade"
                  :active.sync="waitTimeout"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <loading
                  transition="fade"
                  :active.sync="waitTimeoutContact"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <v-card-title>Demande de rappels en attente</v-card-title>
                <v-card-text v-if="contact.length > 0">
                  <v-icon size="48" color="EoleYellow">mdi-minus-thick</v-icon>
                  <h2>{{ contact.length }}</h2>
                </v-card-text>
                <v-row v-else align="center">
                  <v-card-text>
                    <p>Il n'y a pour le moment pas de demandes de rappel.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column rounded-xl" link v-bind="attrs" v-on="on" outlined elevation="3">
                <loading
                  transition="fade"
                  :active.sync="waitTimeout"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <loading
                  transition="fade"
                  :active.sync="waitTimeoutContact"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="dots"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <v-card-title>Demande de rappels traités</v-card-title>

                <v-row v-if="contactTreated.length > 0" align="center">
                  <v-card-text>
                    <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon>
                    <h2>{{ contactTreated.length }}</h2>
                  </v-card-text>
                </v-row>
                <v-row v-else align="center">
                  <v-card-text>
                    <p>Il n'y a pour le moment pas d'historique.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Les appels traités sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-dialog max-width="1200">
            <template v-slot:activator="{ on, attrs }">
              <v-card v-bind="attrs" v-on="on" outlined flat elevation="3" class="flex d-flex flex-column rounded-xl">
                <v-card-title></v-card-title>
                <v-row align="center"
                  ><v-card-text>
                    <v-icon size="48" color="EoleBlue">mdi-plus-circle</v-icon>
                    <p class="text-h6">Ajouter un demande de rappel</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>

            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
              <v-card-text style="background-color: #f1f4f9">
                <v-row>
                  <v-col cols="12" md="3" class="no-padding ml-n2">
                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                      <v-card-title>
                        <v-img
                          :src="require('@/assets/images/logos/anavel_logo.png')"
                          max-height="150px"
                          max-width="120px"
                          alt="logo"
                          contain
                        ></v-img>
                      </v-card-title>
                      <v-card-title class="text-center justify-center muller-capitalized"
                        >Mes Services Assurance</v-card-title
                      >
                      <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                      <v-card-text class="no-padding">
                        <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                        </v-img>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="9" class="no-padding d-flex">
                    <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                      <v-card-title>
                        <span class="text-h5">Effectuer une nouvelle demande de rappel</span>
                      </v-card-title>
                      <v-card-text>
                        <v-stepper v-model="e1" flat>
                          <v-stepper-items>
                            <v-stepper-content :step="1" class="text-center transparent-bg">
                              <v-card-subtitle>Quelle est la typologie de votre client ? </v-card-subtitle>
                              <v-row class="text-center align-center d-inline-flex row-study mb-5">
                                <v-col cols="12" md="6" v-for="item in clientTypologyProposed" v-bind:key="item.name">
                                  <v-card
                                    @mouseover="item.hovered = true"
                                    @mouseleave="item.hovered = false"
                                    v-on:click="
                                      clientTypologie = item.value
                                      e1 = item.e1
                                    "
                                    outlined
                                    width="200"
                                    height="200"
                                    elevation="3"
                                    class="align-center d-flex rounded-xl"
                                  >
                                    <v-card-text class="text-center pa-5">
                                      <i
                                        :class="[
                                          'mt-4 fa-solid ',
                                          item.icon,
                                          'fa-5x',
                                          item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                        ]"
                                      ></i>
                                      <p class="mt-2">{{ item.name }}</p>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                              <v-card-text v-if="!!clientTypologie && clientTypologie === 1">
                                <v-container>
                                  <v-form ref="formContact" v-model="validityFormContact" lazy-validation>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-select
                                          dense
                                          :rules="genderRules"
                                          prepend-inner-icon="mdi-account"
                                          outlined
                                          :items="['M.', 'Mme', 'Mlle']"
                                          label="Genre"
                                          v-model="editedItem.gender"
                                          required
                                        >
                                        </v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                          dense
                                          :rules="lastnameRules"
                                          prepend-inner-icon="mdi-account"
                                          outlined
                                          label="Nom"
                                          v-model="editedItem.lastName"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                          dense
                                          :rules="firstnameRules"
                                          prepend-inner-icon="mdi-account"
                                          outlined
                                          label="Prénom"
                                          v-model="editedItem.firstName"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="6" class="mt-n5">
                                        <v-text-field
                                          dense
                                          @input="onPaste"
                                          :rules="phoneRules"
                                          counter="10"
                                          prepend-inner-icon="mdi-phone"
                                          outlined
                                          label="Numéro de téléphone"
                                          v-model="editedItem.phoneNumber"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="6"> </v-col>
                                      <v-col cols="6" class="mt-n5">
                                        <v-text-field
                                          dense
                                          prepend-inner-icon="mdi-email"
                                          outlined
                                          label="Email"
                                          v-model="editedItem.email"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="6" class="mt-n5">
                                        <v-text-field
                                          dense
                                          counter="8"
                                          prepend-inner-icon="mdi-identifier"
                                          outlined
                                          label="ID GRC"
                                          v-model="editedItem.idGRC"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" class="mt-n5">
                                        <v-select
                                          dense
                                          :rules="agencyRules"
                                          prepend-inner-icon="mdi-office-building-marker"
                                          outlined
                                          :items="['Combourg', 'Rennes', 'St Grégoire']"
                                          label="Agence"
                                          v-model="editedItem.agence"
                                          required
                                        >
                                        </v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" class="mt-n5">
                                        <v-select
                                          dense
                                          @change="func"
                                          :rules="collaboratorRules"
                                          prepend-inner-icon="mdi-account-tie"
                                          outlined
                                          :items="collaboratorsAvailable"
                                          item-text="completName"
                                          label="Attribuer à"
                                          v-model="editedItem.id"
                                          item-value="id"
                                          required
                                        >
                                          <template v-slot:item="{ item }">
                                            <v-list-item-avatar size="24" v-if="item.avatarUrl != null">
                                              <v-img :src="item.avatarUrl"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-avatar size="24" v-else color="EoleBlue">
                                              <span class="white--text text-center ml-1" style="font-size: 0.7rem">{{
                                                item.firstname[0] + item.lastname[0]
                                              }}</span>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title v-html="item.completName"></v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-select>
                                      </v-col>
                                      <v-col cols="12" class="mt-n5">
                                        <v-textarea
                                          dense
                                          :rules="reasonRules"
                                          prepend-inner-icon="mdi-message-reply-text"
                                          outlined
                                          label="Motif de l'appel"
                                          v-model="editedItem.reason"
                                          required
                                        >
                                        </v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-form>
                                </v-container>
                              </v-card-text>
                              <v-card-text v-if="!!clientTypologie && clientTypologie === 2">
                                <v-container>
                                  <v-form ref="formContactPro" v-model="validityFormContactPro" lazy-validation>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="12">
                                        <v-text-field
                                          dense
                                          prepend-inner-icon="mdi-account"
                                          outlined
                                          label="Raison sociale"
                                          v-model="editedItem.lastName"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-text-field
                                          dense
                                          @input="onPaste"
                                          :rules="phoneRules"
                                          counter="10"
                                          prepend-inner-icon="mdi-phone"
                                          outlined
                                          label="Numéro de téléphone"
                                          v-model="editedItem.phoneNumber"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="6"> </v-col>
                                      <v-col cols="6">
                                        <v-text-field
                                          dense
                                          prepend-inner-icon="mdi-email"
                                          outlined
                                          label="Email"
                                          v-model="editedItem.email"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-text-field
                                          dense
                                          counter="8"
                                          prepend-inner-icon="mdi-identifier"
                                          outlined
                                          label="ID GRC"
                                          v-model="editedItem.idGRC"
                                          required
                                        >
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <v-select
                                          dense
                                          :rules="agencyRules"
                                          prepend-inner-icon="mdi-office-building-marker"
                                          outlined
                                          :items="['Combourg', 'Rennes', 'St Grégoire']"
                                          label="Agence"
                                          v-model="editedItem.agence"
                                          required
                                        >
                                        </v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <v-select
                                          dense
                                          @change="func"
                                          :rules="collaboratorRules"
                                          prepend-inner-icon="mdi-account-tie"
                                          outlined
                                          :items="collaboratorsAvailable"
                                          item-text="completName"
                                          label="Attribuer à"
                                          v-model="editedItem.id"
                                          item-value="id"
                                          required
                                        >
                                          <template v-slot:item="{ item }">
                                            <v-list-item-avatar size="24">
                                              <v-img :src="item.avatarUrl"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title v-html="item.completName"></v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-select>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-textarea
                                          dense
                                          :rules="reasonRules"
                                          prepend-inner-icon="mdi-message-reply-text"
                                          outlined
                                          label="Motif de l'appel"
                                          v-model="editedItem.reason"
                                          required
                                        >
                                        </v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-form>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <!-- <v-btn color="EoleYellow" text @click="close"> Annuler </v-btn> -->
                                <v-btn v-if="e1 > 0" text @click="e1--"> retour </v-btn>

                                <v-spacer></v-spacer>

                                <v-btn text @click="editedIndex === -1 && validateFormContact(editedItem.id)">
                                  Terminer
                                </v-btn>
                              </v-card-actions>
                            </v-stepper-content>
                          </v-stepper-items>
                        </v-stepper>
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <div v-if="grade === 'collaborateur'">
        <loading
          transition="fade"
          :active.sync="waitTimeout"
          :can-cancel="false"
          :is-full-page="false"
          loader="dots"
          color="#efb639"
          :opacity="1"
        >
        </loading>
        <v-card outlined class="text-center">
          <v-card-text class="profilBannerAccount" style="height: 100px">
            <v-row>
              <v-col cols="12" md="6" class="text-left"> </v-col>
              <v-col cols="12" md="6" class="text-right"> </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="profilSeparator">
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text class="profilBadge">
            <v-avatar v-if="avatarUrlCollaborator != null" ref="avatar" size="96" color="" class="elevation-10">
              <v-img :src="avatarUrlCollaborator"> </v-img>
            </v-avatar>
            <v-avatar ref="avatar" size="96" color="EoleBlue" v-else>
              <span class="white--text text-h3">{{ firstnameCollaborator[0] + lastnameCollaborator[0] }}</span>
            </v-avatar>
          </v-card-text>
          <v-card-text class="profilTabs mb-15">
            <v-row>
              <v-col class="text-left" cols="12" md="9">
                <v-tabs
                  class="mt-5"
                  color="EoleYellow"
                  v-show="!waitTimeout"
                  v-model="tab"
                  style="box-shadow: none !important"
                  background-color="transparent"
                >
                  <v-tab href="#tab-1">
                    Demandes de rappel
                    <!-- <v-icon>mdi-phone</v-icon> -->
                  </v-tab>

                  <v-tab href="#tab-2">
                    Historique des demandes
                    <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                  </v-tab>
                  <v-tab href="#tab-3">
                    Statistiques
                    <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" md="2" align-self="center">
                <v-dialog v-model="dialog" max-width="1200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" rounded>
                      <v-icon left>mdi-phone-plus</v-icon>
                      Ajouter un rappel
                    </v-btn>
                  </template>
                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                    <v-card-text style="background-color: #f1f4f9">
                      <v-row>
                        <v-col cols="12" md="3" class="no-padding ml-n2">
                          <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                            <v-card-title>
                              <v-img
                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                max-height="150px"
                                max-width="120px"
                                alt="logo"
                                contain
                              ></v-img>
                            </v-card-title>
                            <v-card-title class="text-center justify-center muller-capitalized"
                              >Mes Services Assurance</v-card-title
                            >
                            <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                            <v-card-text class="no-padding">
                              <v-img
                                :src="require('@/assets/images/logos/layer.png')"
                                alt="logo"
                                width="300px"
                                class=""
                              >
                              </v-img>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="9" class="no-padding d-flex">
                          <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                            <v-card-title>
                              <span class="text-h5">Effectuer une nouvelle demande de rappel</span>
                            </v-card-title>
                            <v-card-text>
                              <v-stepper v-model="e1" flat>
                                <v-stepper-items>
                                  <v-stepper-content :step="1" class="text-center transparent-bg">
                                    <v-card-subtitle>Quelle est la typologie de votre client ? </v-card-subtitle>
                                    <v-row class="text-center align-center d-inline-flex row-study mb-5">
                                      <v-col
                                        cols="12"
                                        md="6"
                                        v-for="item in clientTypologyProposed"
                                        v-bind:key="item.name"
                                      >
                                        <v-card
                                          @mouseover="item.hovered = true"
                                          @mouseleave="item.hovered = false"
                                          v-on:click="
                                            clientTypologie = item.value
                                            e1 = item.e1
                                          "
                                          outlined
                                          width="200"
                                          height="200"
                                          elevation="3"
                                          class="align-center d-flex rounded-xl"
                                        >
                                          <v-card-text class="text-center pa-5">
                                            <i
                                              :class="[
                                                'mt-4 fa-solid ',
                                                item.icon,
                                                'fa-5x',
                                                item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                              ]"
                                            ></i>
                                            <p class="mt-2">{{ item.name }}</p>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-stepper-content>

                                  <v-stepper-content step="2">
                                    <v-card-text v-if="!!clientTypologie && clientTypologie === 1">
                                      <v-container>
                                        <v-form ref="formContact" v-model="validityFormContact" lazy-validation>
                                          <v-row>
                                            <v-col cols="12" sm="6" md="4">
                                              <v-select
                                                dense
                                                :rules="genderRules"
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                :items="['M.', 'Mme', 'Mlle']"
                                                label="Genre"
                                                v-model="editedItem.gender"
                                                required
                                              >
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                              <v-text-field
                                                dense
                                                :rules="lastnameRules"
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                label="Nom"
                                                v-model="editedItem.lastName"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                              <v-text-field
                                                dense
                                                :rules="firstnameRules"
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                label="Prénom"
                                                v-model="editedItem.firstName"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="mt-n5">
                                              <v-text-field
                                                dense
                                                @input="onPaste"
                                                :rules="phoneRules"
                                                counter="10"
                                                prepend-inner-icon="mdi-phone"
                                                outlined
                                                label="Numéro de téléphone"
                                                v-model="editedItem.phoneNumber"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6"> </v-col>
                                            <v-col cols="6" class="mt-n5">
                                              <v-text-field
                                                dense
                                                prepend-inner-icon="mdi-email"
                                                outlined
                                                label="Email"
                                                v-model="editedItem.email"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="mt-n5">
                                              <v-text-field
                                                dense
                                                counter="8"
                                                prepend-inner-icon="mdi-identifier"
                                                outlined
                                                label="ID GRC"
                                                v-model="editedItem.idGRC"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="mt-n5">
                                              <v-select
                                                dense
                                                :rules="agencyRules"
                                                prepend-inner-icon="mdi-office-building-marker"
                                                outlined
                                                :items="['Combourg', 'Rennes', 'St Grégoire']"
                                                label="Agence"
                                                v-model="editedItem.agence"
                                                required
                                              >
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="mt-n5">
                                              <v-select
                                                dense
                                                @change="func"
                                                :rules="collaboratorRules"
                                                prepend-inner-icon="mdi-account-tie"
                                                outlined
                                                :items="collaboratorsAvailable"
                                                item-text="completName"
                                                label="Attribuer à"
                                                v-model="editedItem.id"
                                                item-value="id"
                                                required
                                              >
                                                <template v-slot:item="{ item }">
                                                  <v-list-item-avatar size="24" v-if="item.avatarUrl != null">
                                                    <v-img :src="item.avatarUrl"></v-img>
                                                  </v-list-item-avatar>
                                                  <v-list-item-avatar size="24" v-else color="EoleBlue">
                                                    <span
                                                      class="white--text text-center ml-1"
                                                      style="font-size: 0.7rem"
                                                      >{{ item.firstname[0] + item.lastname[0] }}</span
                                                    >
                                                  </v-list-item-avatar>
                                                  <v-list-item-content>
                                                    <v-list-item-title v-html="item.completName">{{
                                                      item
                                                    }}</v-list-item-title>
                                                  </v-list-item-content>
                                                  <v-list-item-action>
                                                    <v-chip
                                                      class="ma-3"
                                                      v-if="item.solicitationStatus === 1"
                                                      color="EoleErrorBase"
                                                      text-color="white"
                                                      small
                                                    >
                                                      Très solicité</v-chip
                                                    >
                                                    <v-chip
                                                      class="ma-3"
                                                      v-else-if="item.solicitationStatus === 2"
                                                      color="EoleYellow"
                                                      small
                                                      >Solicité</v-chip
                                                    >
                                                  </v-list-item-action>
                                                </template>
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" class="mt-n5">
                                              <v-textarea
                                                dense
                                                :rules="reasonRules"
                                                prepend-inner-icon="mdi-message-reply-text"
                                                outlined
                                                label="Motif de l'appel"
                                                v-model="editedItem.reason"
                                                required
                                              >
                                              </v-textarea>
                                            </v-col>
                                          </v-row>
                                        </v-form>
                                      </v-container>
                                    </v-card-text>
                                    <v-card-text v-if="!!clientTypologie && clientTypologie === 2">
                                      <v-container>
                                        <v-form ref="formContactPro" v-model="validityFormContactPro" lazy-validation>
                                          <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                              <v-text-field
                                                dense
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                label="Raison sociale"
                                                v-model="editedItem.lastName"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                              <v-text-field
                                                dense
                                                @input="onPaste"
                                                :rules="phoneRules"
                                                counter="10"
                                                prepend-inner-icon="mdi-phone"
                                                outlined
                                                label="Numéro de téléphone"
                                                v-model="editedItem.phoneNumber"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6"> </v-col>
                                            <v-col cols="6">
                                              <v-text-field
                                                dense
                                                prepend-inner-icon="mdi-email"
                                                outlined
                                                label="Email"
                                                v-model="editedItem.email"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                              <v-text-field
                                                dense
                                                counter="8"
                                                prepend-inner-icon="mdi-identifier"
                                                outlined
                                                label="ID GRC"
                                                v-model="editedItem.idGRC"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                              <v-select
                                                dense
                                                :rules="agencyRules"
                                                prepend-inner-icon="mdi-office-building-marker"
                                                outlined
                                                :items="['Combourg', 'Rennes', 'St Grégoire']"
                                                label="Agence"
                                                v-model="editedItem.agence"
                                                required
                                              >
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                              <v-select
                                                dense
                                                @change="func"
                                                :rules="collaboratorRules"
                                                prepend-inner-icon="mdi-account-tie"
                                                outlined
                                                :items="collaboratorsAvailable"
                                                item-text="completName"
                                                label="Attribuer à"
                                                v-model="editedItem.id"
                                                item-value="id"
                                                required
                                              >
                                                <template v-slot:item="{ item }">
                                                  <v-list-item-avatar size="24">
                                                    <v-img :src="item.avatarUrl"></v-img>
                                                  </v-list-item-avatar>
                                                  <v-list-item-content>
                                                    <v-list-item-title v-html="item.completName"></v-list-item-title>
                                                  </v-list-item-content>
                                                </template>
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-textarea
                                                dense
                                                :rules="reasonRules"
                                                prepend-inner-icon="mdi-message-reply-text"
                                                outlined
                                                label="Motif de l'appel"
                                                v-model="editedItem.reason"
                                                required
                                              >
                                              </v-textarea>
                                            </v-col>
                                          </v-row>
                                        </v-form>
                                      </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                      <!-- <v-btn color="EoleYellow" text @click="close"> Annuler </v-btn> -->
                                      <v-btn v-if="e1 > 0" text @click="e1--"> retour </v-btn>

                                      <v-spacer></v-spacer>

                                      <v-btn text @click="editedIndex === -1 && validateFormContact(editedItem.id)">
                                        Terminer
                                      </v-btn>
                                    </v-card-actions>
                                  </v-stepper-content>
                                </v-stepper-items>
                              </v-stepper>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="mt-10">
            <v-tabs-items v-model="tab" style="background-color: transparent !important">
              <loading
                transition="fade"
                :active.sync="waitTimeoutContact"
                :can-cancel="false"
                :is-full-page="false"
                loader="spinner"
                color="#efb639"
                :opacity="1"
              >
              </loading>
              <v-tab-item :value="'tab-1'" class="mb-5">
                <v-row v-if="contact.length > 0">
                  <v-col cols="12" md="12">
                    <div class="text-center">
                      <v-pagination
                        v-model="pageContact"
                        :length="pageContactCount"
                        circle
                        :total-visible="5"
                        navigation-color="EoleYellow"
                      ></v-pagination>
                    </div>
                  </v-col>
                  <v-col class="d-flex" cols="12" md="4" v-for="(call, index) in paginatedContact" :key="index">
                    <v-card outlined elevation="3" class="text-center flex d-flex flex-column rounded-xl">
                      <v-row class="pa-5">
                        <v-col class="text-left" cols="12" md="9"></v-col>
                        <v-col class="text-right" cols="12" md="3">
                          <v-menu right transition="slide-x-transition" close-on-content-click>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item link color="EoleBlue" :disabled="transferInProgress">
                                <v-dialog
                                  transition="slide-x-transition"
                                  max-width="1200"
                                  v-model="dialogTransferContact"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-bind="attrs" v-on="on"> Assigner à nouveau </v-list-item-title>
                                    <v-list-item-actions>
                                      <i class="fa-solid fa-share-from-square ml-2"></i>
                                    </v-list-item-actions>
                                  </template>
                                  <template v-slot:default="dialogContact">
                                    <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                      <v-card-text style="background-color: #f1f4f9">
                                        <v-row>
                                          <v-col cols="12" md="3" class="no-padding ml-n2">
                                            <v-card
                                              class="rounded-l-xl"
                                              color="#f1f4f9"
                                              style="background-color: #f1f4f9"
                                              flat
                                            >
                                              <v-card-title>
                                                <v-img
                                                  :src="require('@/assets/images/logos/anavel_logo.png')"
                                                  max-height="150px"
                                                  max-width="120px"
                                                  alt="logo"
                                                  contain
                                                ></v-img>
                                              </v-card-title>
                                              <v-card-title class="text-center justify-center muller-capitalized"
                                                >Mes Services Assurance</v-card-title
                                              >
                                              <v-card-subtitle class="text-center justify-center"
                                                >Un logiciel ANAVEL</v-card-subtitle
                                              >
                                              <v-card-text class="no-padding">
                                                <v-img
                                                  :src="require('@/assets/images/logos/layer.png')"
                                                  alt="logo"
                                                  width="300px"
                                                  class=""
                                                >
                                                </v-img>
                                              </v-card-text>
                                            </v-card>
                                          </v-col>
                                          <v-col cols="12" md="9" class="no-padding d-flex">
                                            <v-card
                                              class="rounded-r-xl flex-fill flex d-flex flex-column"
                                              flat
                                              :loading="transferInProgress"
                                            >
                                              <v-card-title class="text-h5">
                                                <v-row>
                                                  <v-col cols="12" md="10" class="text-left"
                                                    >Changement d'assignation</v-col
                                                  >
                                                  <v-col cols="12" md="2">
                                                    <v-chip> Identifiant : N°{{ call.idContact }}</v-chip>
                                                  </v-col>
                                                </v-row>
                                              </v-card-title>
                                              <v-card-subtitle
                                                >Sélectionnez un collaborateur pour changer
                                                l'assignation</v-card-subtitle
                                              >

                                              <v-card-text class="no-padding">
                                                <v-list dense :disabled="transferInProgress">
                                                  <v-list-item
                                                    :disabled="transferInProgress"
                                                    color="EoleBlue"
                                                    v-for="(collaborator, index) in collaboratorsAvailable"
                                                    :key="index"
                                                    link
                                                    @click="
                                                      transferContact(
                                                        call.idContact,
                                                        collaborator.id,
                                                        collaborator.completName,
                                                        collaborator.email,
                                                      )
                                                    "
                                                  >
                                                    <v-list-item-avatar
                                                      v-if="collaborator.avatarUrl != null"
                                                      class="elevation-10"
                                                    >
                                                      <v-img :src="collaborator.avatarUrl"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-avatar v-else color="EoleBlue" class="text-center">
                                                      <v-avatar color="red">
                                                        <span class="white--text mr-2">{{
                                                          collaborator.firstname[0] + collaborator.lastname[0]
                                                        }}</span>
                                                      </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-title
                                                      >{{ collaborator.completName }}
                                                    </v-list-item-title>

                                                    <v-list-item-action class="mr-5">
                                                      <v-list-item-action-text v-text=""></v-list-item-action-text>

                                                      <v-chip
                                                        class="ma-3"
                                                        v-if="collaborator.solicitationStatus === 1"
                                                        color="EoleErrorBase"
                                                        text-color="white"
                                                        small
                                                      >
                                                        Très solicité</v-chip
                                                      >
                                                      <v-chip
                                                        class="ma-3"
                                                        v-else-if="collaborator.solicitationStatus === 2"
                                                        color="EoleYellow"
                                                        small
                                                        >Solicité</v-chip
                                                      >
                                                    </v-list-item-action>
                                                  </v-list-item>
                                                </v-list>
                                              </v-card-text>
                                              <v-spacer></v-spacer>
                                              <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </template>
                                </v-dialog>
                              </v-list-item>
                              <v-dialog transition="dialog-bottom-transition" max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item
                                    v-bind="attrs"
                                    v-on="on"
                                    color="EoleError"
                                    link
                                    @click="archiveContact(call.idContact)"
                                  >
                                    <v-list-item-title> Demande traitée</v-list-item-title>
                                    <v-list-item-actions>
                                      <i class="fa-solid fa-circle-check ml-2 EoleGreen--text"></i>
                                    </v-list-item-actions>
                                  </v-list-item>
                                </template>
                                <template v-slot:default="dialogArchiveContact">
                                  <v-card>
                                    <v-card-text class="text-center">
                                      <v-img
                                        src="https://anavel.bzh/assets/success.gif"
                                        max-width="126px"
                                        class="mx-auto"
                                      ></v-img>
                                      Votre demande à été traitée, il ne vous reste plus que
                                      <strong> {{ contact.length - 1 }} appels</strong> !
                                    </v-card-text>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-card-text class="flex-grow-1">
                        <v-row class="flex-column">
                          <v-col v-if="call.attributorName === null">
                            <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                          </v-col>

                          <v-col v-else class="text-left" cols="12" md="6">Demande créée le {{ call.datetime }}</v-col>
                          <v-col class="text-right">
                            <v-chip color="EoleYellow" small>Agence de {{ call.agency }}</v-chip>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              dense
                              :id="`identity-${index}`"
                              outlined
                              label="Identité"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-account"
                              :value="call.lastname + ' ' + call.firstname"
                              @click:append="copyClipboard(`identity-${index}`)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-text-field
                              dense
                              :id="`email-${index}`"
                              outlined
                              label="Email"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-email-outline"
                              :value="call.email"
                              @click:append="copyClipboard(`email-${index}`)"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn
                              :disabled="call.email === null"
                              class="white--text"
                              :href="`mailto:${call.email}`"
                              color="EoleBlue"
                              >Contacter</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-text-field
                              dense
                              :id="`phone-${index}`"
                              outlined
                              label="Numéro de téléphone"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-phone"
                              :value="call.phoneNumber"
                              @click:append="copyClipboard(`phone-${index}`)"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn class="white--text" :href="`tel:${call.phoneNumber}`" color="EoleBlue"
                              >Appeler</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              dense
                              :id="`idGRC-${index}`"
                              outlined
                              label="Identifiant GRC"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-identifier"
                              :value="call.idGRC"
                              @click:append="copyClipboard('idGRC')"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-textarea
                              dense
                              :id="`reason-${index}`"
                              no-resize
                              type="code"
                              outlined
                              label="Motif de l'appel"
                              readonly
                              :value="call.reason"
                              rows="1"
                              auto-grow
                              append-icon="mdi-content-copy"
                              @click:append="copyClipboard(`reason-${index}`)"
                            >
                            </v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else class="text-center justify-center">
                  <v-alert color="transparent">
                    <loading
                      transition="fade"
                      :active.sync="waitTimeoutContact"
                      :can-cancel="false"
                      :is-full-page="false"
                      loader="spinner"
                      color="#efb639"
                      :opacity="1"
                    >
                    </loading>
                    <h2>Bonjour {{ collaborator.firstname }},</h2>

                    <v-img
                      class="mx-auto"
                      max-width="350"
                      :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                    ></v-img>
                    Vous n'avez aucune demande de rappel. <br />
                    N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                  </v-alert>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-2'">
                <v-card-text class="no-padding">
                  <v-row v-if="contactTreated.length > 0">
                    <loading
                      transition="fade"
                      :active.sync="waitTimeoutContact"
                      :can-cancel="false"
                      :is-full-page="false"
                      loader="spinner"
                      color="#efb639"
                      :opacity="1"
                    >
                    </loading>
                    <v-col cols="12">
                      <v-data-table
                        item-key="idContact"
                        class="elevation-0 no-padding"
                        :loading="loading"
                        loading-text="Chargement des produits en cours... Merci de patienter"
                        :page.sync="page"
                        :items-per-page="10"
                        :headers="headersContactTreated"
                        :items="contactTreated"
                        hide-default-footer
                        @page-count="pageCount = $event"
                        sort-by="calories"
                        :footer-props="{
                          'items-per-page-text': 'produits par page(s)',
                        }"
                        :search="searchContactTreated"
                      >
                        <template v-slot:top>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-text-field
                                prepend-icon="mdi-magnify"
                                v-model="searchContactTreated"
                                label="Rechercher parmi vos demandes traitées"
                                class="mx-4"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item.agency="{ item }">
                          <v-chip small color="EoleYellow"> Agence de {{ item.agency }} </v-chip>
                        </template>

                        <template v-slot:item.firstname="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
                        <template v-slot:item.actions="{ item }">
                          <v-dialog max-width="1200">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" rounded icon fab>
                                <i class="fa-solid fa-eye"></i>
                              </v-btn>
                            </template>
                            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized"
                                        >Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL</v-card-subtitle
                                      >
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="9" class="no-padding d-flex">
                                    <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                      <v-card-title>
                                        <span class="text-h5">Votre demande portant le N°{{ item.idContact }}</span>
                                      </v-card-title>
                                      <v-card-text class="flex-grow-1">
                                        <v-row class="flex-column">
                                          <v-col class="text-left">Demande créée le {{ item.datetime }} </v-col>
                                          <v-col class="text-right">
                                            <v-chip color="EoleYellow" small>Agence de {{ item.agency }}</v-chip>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="identity"
                                              outlined
                                              label="Identité"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-account"
                                              :value="item.lastname + ' ' + item.firstname"
                                              @click:append="copyClipboard('identity')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="email"
                                              outlined
                                              label="Email"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-email"
                                              :value="item.email"
                                              @click:append="copyClipboard('email')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" md="7">
                                            <v-text-field
                                              dense
                                              id="phone"
                                              outlined
                                              label="Numéro de téléphone"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-phone"
                                              :value="item.phoneNumber"
                                              @click:append="copyClipboard('phone')"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col class="mt-2" cols="12" md="2">
                                            <v-btn
                                              class="white--text"
                                              :href="`tel:${item.phoneNumber}`"
                                              color="EoleBlue"
                                              >Appeler</v-btn
                                            >
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="idGRC"
                                              outlined
                                              label="Identifiant GRC"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-identifier"
                                              :value="item.idGRC"
                                              @click:append="copyClipboard('idGRC')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-textarea
                                              dense
                                              id="reason"
                                              no-resize
                                              type="code"
                                              outlined
                                              label="Motif de l'appel"
                                              readonly
                                              :value="item.reason"
                                              rows="1"
                                              auto-grow
                                              append-icon="mdi-content-copy"
                                              @click:append="copyClipboard('reason')"
                                            ></v-textarea>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                      <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </template>

                        <template v-slot:no-data>
                          <v-alert color="error" text class="ma-2">
                            <div class="d-flex align-start">
                              <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                              <div class="ms-3">
                                <p class="text-base font-weight-medium mb-1">
                                  Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                </p>
                              </div>
                            </div>
                          </v-alert>
                          <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()"> Réessayer</v-btn>
                        </template>
                      </v-data-table>
                      <div class="text-center pt-2">
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                          :total-visible="5"
                          circle
                          navigation-color="EoleYellow"
                        ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-else class="text-center justify-center">
                    <v-alert color="transparent">
                      <loading
                        transition="fade"
                        :active.sync="waitTimeoutContact"
                        :can-cancel="false"
                        :is-full-page="false"
                        loader="spinner"
                        color="#efb639"
                        :opacity="1"
                      >
                      </loading>
                      <h2>Bonjour {{ collaborator.firstname }},</h2>

                      <v-img
                        class="mx-auto"
                        max-width="350"
                        :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                      ></v-img>
                      Vous n'avez aucune demande de rappel. <br />
                      N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                    </v-alert>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item :value="'tab-3'">
                <v-card-text class="text-left">
                  <v-row>
                    <v-col class="d-flex" cols="12" md="6" sm="12">
                      <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Répartition par services des demandes</v-card-title>
                            <v-card-subtitle class="text-left">Pour l'année en cours</v-card-subtitle>
                          </v-col>
                        </v-row>

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart
                              :options="monthlyRequestsServicesChartOptions"
                              :series="monthlyRequestsServicesSeries"
                              type="bar"
                            ></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                    <!-- <v-col class="d-flex" cols="12" md="6" sm="12">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                            <v-row>
                              <v-col cols="12" md="2" class="text-left">
                                <v-card-title class="text-left">
                                  <v-avatar color="EoleBlueLighten" class="mr-2">
                                    <i
                                      class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                      style="font-size: 1.5rem"
                                    ></i> </v-avatar
                                ></v-card-title>
                              </v-col>
                              <v-col cols="12" md="10" class="text-left ml-n15">
                                <v-card-title class="text-left"
                                  >Répartition total des demandes traitées pour l'agence</v-card-title
                                >
                                <v-card-subtitle class="text-left">Pour l'année en cours</v-card-subtitle>
                              </v-col>
                            </v-row>
                           
                            <v-row align="center">
                              <v-card-text class="text-center align-center">
                                <apexchart
                                  :options="monthlyRequestsChartOptions"
                                  :series="monthlyRequestsSeries"
                                  type="bar"
                                ></apexchart>
                              </v-card-text>
                            </v-row>
                          </v-card>
                        </template>
                        <span>Le nombre de réclamations de l'agence. </span>
                      </v-tooltip>
                    </v-col> -->
                    <v-col class="d-flex" cols="12" md="6" sm="12">
                      <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Répartition sur mon service</v-card-title>
                            <v-card-subtitle class="text-left">Sur les demandes en cours</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart
                              type="pie"
                              :options="pieChartOptions"
                              :series="pieSeries"
                              height="250px"
                            ></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes dernières demandes reçus</v-card-title>
                            <v-card-subtitle class="text-left">Sur les 7 derniers jours</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes demandes reçus traitées</v-card-title>
                            <v-card-subtitle class="text-left">Sur les dernières années</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart :options="annualChartOptions" :series="annualSeries" type="bar"></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-share-from-square fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes demandes envoyés</v-card-title>
                            <v-card-subtitle class="text-left">Sur les 7 derniers jours</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart type="bar" :options="sendChartOptions" :series="sendSeries"></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-share-from-square fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes demandes envoyées</v-card-title>
                            <v-card-subtitle class="text-left">Sur les dernières années</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart
                              :options="annualSendChartOptions"
                              :series="annualSendSeries"
                              type="bar"
                            ></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </div>

      <v-row v-else class="my-5 mx-5">
        <v-col cols="12" md="3">
          <v-card outlined>
            <v-card-text class="pa-5 no-padding">
              <loading
                transition="fade"
                :active.sync="waitTimeout"
                :can-cancel="false"
                :is-full-page="false"
                loader="spinner"
                color="#efb639"
                :opacity="1"
              >
              </loading>

              <v-treeview
                dense
                :load-children="fetchUsers"
                open-all
                activatable
                :active.sync="active"
                :items="items"
                :open.sync="open"
                color="EoleYellow"
                transition
                selected-color="EoleYellow"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.children">mdi-phone</v-icon>
                  <v-spacer></v-spacer>
                  <v-badge
                    color="EoleError"
                    :content="item.contactNumber"
                    value="5"
                    class="my-2 mr-2"
                    v-if="item.id && item.contactNumber > 0"
                    bordered
                    bottom
                    offset-x="15"
                    offset-y="15"
                  >
                    <v-avatar size="40" :color="item.avatarUrl === null ? 'EoleBlue' : null" class="elevation-7">
                      <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                      <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                      <!-- <v-icon v-else-if="item.id && !item.children" size="36">mdi-account</v-icon> -->
                    </v-avatar>
                  </v-badge>
                  <v-avatar
                    class="my-2 mr-2 elevation-7"
                    v-else-if="item.id && item.contactNumber === 0"
                    size="40"
                    :color="item.avatarUrl === null ? 'EoleBlue' : null"
                  >
                    <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                    <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                    <!-- <v-icon v-else-if="item.id && !item.children" size="36">mdi-account</v-icon> -->
                  </v-avatar>

                  <!-- <v-avatar
                    v-else-if="item.id && !item.children"
                    size="40"
                    :color="item.avatarUrl === null ? item.color : null"
                    style="border: 1px solid !important"
                  >
                    <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                    <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                  </v-avatar> -->
                  {{ item.completName }}
                </template>
              </v-treeview>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center no-padding align-center mt-4 mr-2 EoleBlue--text">
              <p class="text-caption">{{ contactTotalCount }} appels en attente</p>
              <br />
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="9">
          <v-scroll-y-transition mode="out-in">
            <v-row v-if="!selected" align="center" class="text-left">
              <v-col>
                <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense>
                  Veuillez selectionner un partenaire à afficher dans cette fenêtre.</v-alert
                >
              </v-col>
            </v-row>
            <v-card v-else :key="selected.id" class="mx-auto" flat>
              <v-card outlined class="text-center">
                <v-card-text class="profilBannerAccount" style="height: 100px">
                  <v-row>
                    <v-col cols="12" md="6" class="text-left"> </v-col>
                    <v-col cols="12" md="6" class="text-right"> </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="profilSeparator">
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-text class="profilBadge">
                  <v-avatar v-if="avatarUrl != null" ref="avatar" size="96" class="elevation-7">
                    <v-img :src="selected.avatarUrl"> </v-img>
                  </v-avatar>
                  <v-avatar ref="avatar" size="96" color="EoleBlue" v-else>
                    <span class="white--text text-h3">{{ selected.firstname[0] + selected.lastname[0] }}</span>
                  </v-avatar>
                </v-card-text>
                <v-card-text class="profilTabs mb-15">
                  <v-row>
                    <v-col cols="12" md="9">
                      <v-tabs
                        color="EoleYellow"
                        class="text-left mt-5"
                        v-model="tab"
                        style="box-shadow: none !important"
                        background-color="transparent"
                        :show-arrows="false"
                      >
                        <v-tab href="#tab-1"> Demandes de rappel </v-tab>

                        <v-tab href="#tab-2"> Demandes traitées</v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="mt-16">
                  <v-tabs-items v-model="tab">
                    <v-tab-item :value="'tab-1'">
                      <v-row v-if="selected.contactNumber === 0" class="text-left pa-5 justify-center">
                        <v-alert type="error" prominent dense text
                          >Il n'y a aucune demande de rappel pour {{ selected.completName }}</v-alert
                        >
                      </v-row>
                      <v-row class="pa-5">
                        <v-col cols="12" md="6" v-for="(call, index) in contact" :key="index" class="d-flex">
                          <v-card outlined elevation="3" class="text-center flex d-flex flex-column rounded-xl">
                            <v-row class="pa-5">
                              <v-col class="text-left" cols="12" md="9"></v-col>
                              <v-col class="text-right" cols="12" md="3">
                                <v-menu right transition="slide-x-transition" close-on-content-click>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item link color="EoleBlue" :disabled="transferInProgress">
                                      <v-dialog transition="slide-x-transition" max-width="1200">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title v-bind="attrs" v-on="on">
                                            Assigner à nouveau
                                          </v-list-item-title>
                                          <v-list-item-actions>
                                            <i class="fa-solid fa-share-from-square ml-2"></i>
                                          </v-list-item-actions>
                                        </template>
                                        <template v-slot:default="dialogContact">
                                          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                            <v-card-text style="background-color: #f1f4f9">
                                              <v-row>
                                                <v-col cols="12" md="3" class="no-padding ml-n2">
                                                  <v-card
                                                    class="rounded-l-xl"
                                                    color="#f1f4f9"
                                                    style="background-color: #f1f4f9"
                                                    flat
                                                  >
                                                    <v-card-title>
                                                      <v-img
                                                        :src="require('@/assets/images/logos/anavel_logo.png')"
                                                        max-height="150px"
                                                        max-width="120px"
                                                        alt="logo"
                                                        contain
                                                      ></v-img>
                                                    </v-card-title>
                                                    <v-card-title class="text-center justify-center muller-capitalized"
                                                      >Mes Services Assurance</v-card-title
                                                    >
                                                    <v-card-subtitle class="text-center justify-center"
                                                      >Un logiciel ANAVEL</v-card-subtitle
                                                    >
                                                    <v-card-text class="no-padding">
                                                      <v-img
                                                        :src="require('@/assets/images/logos/layer.png')"
                                                        alt="logo"
                                                        width="300px"
                                                        class=""
                                                      >
                                                      </v-img>
                                                    </v-card-text>
                                                  </v-card>
                                                </v-col>
                                                <v-col cols="12" md="9" class="no-padding d-flex">
                                                  <v-card
                                                    class="rounded-r-xl flex-fill flex d-flex flex-column"
                                                    flat
                                                    :loading="transferInProgress"
                                                  >
                                                    <v-card-title class="text-h5">
                                                      <v-row>
                                                        <v-col cols="12" md="10" class="text-left"
                                                          >Changement d'assignation</v-col
                                                        >
                                                        <v-col cols="12" md="2">
                                                          <v-chip> Identifiant : N°{{ call.idContact }}</v-chip>
                                                        </v-col>
                                                      </v-row>
                                                    </v-card-title>

                                                    <v-card-subtitle
                                                      >Sélectionnez un collaborateur pour changer
                                                      l'assignation</v-card-subtitle
                                                    >

                                                    <v-card-text class="no-padding">
                                                      <v-list dense :disabled="transferInProgress">
                                                        <v-list-item
                                                          :disabled="transferInProgress"
                                                          color="EoleBlue"
                                                          v-for="(collaborator, index) in collaboratorsAvailable"
                                                          :key="index"
                                                          link
                                                          @click="
                                                            transferContact(
                                                              call.idContact,
                                                              collaborator.id,
                                                              collaborator.completName,
                                                              collaborator.email,
                                                            )
                                                          "
                                                        >
                                                          <v-list-item-avatar v-if="collaborator.avatarUrl != null">
                                                            <v-img :src="collaborator.avatarUrl"></v-img>
                                                          </v-list-item-avatar>
                                                          <v-list-item-avatar v-else color="EoleBlue">
                                                            <!-- <span class="white--text ml-3">{{
                                                              collaborator.firstname[0] + collaborator.lastname[0]
                                                            }}</span> -->
                                                            <v-avatar color="red">
                                                              <span class="white--text mr-2">{{
                                                                collaborator.firstname[0] + collaborator.lastname[0]
                                                              }}</span>
                                                            </v-avatar>
                                                          </v-list-item-avatar>
                                                          <v-list-item-title
                                                            >{{ collaborator.completName }}
                                                          </v-list-item-title>

                                                          <v-list-item-action class="mr-5">
                                                            <v-list-item-action-text
                                                              v-text=""
                                                            ></v-list-item-action-text>

                                                            <v-chip
                                                              class="ma-3"
                                                              v-if="collaborator.solicitationStatus === 1"
                                                              color="EoleErrorBase"
                                                              text-color="white"
                                                              small
                                                            >
                                                              Très solicité</v-chip
                                                            >
                                                            <v-chip
                                                              class="ma-3"
                                                              v-else-if="collaborator.solicitationStatus === 2"
                                                              color="EoleYellow"
                                                              small
                                                              >Solicité</v-chip
                                                            >
                                                          </v-list-item-action>
                                                        </v-list-item>
                                                      </v-list>
                                                    </v-card-text>
                                                    <v-spacer></v-spacer>
                                                    <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                                  </v-card>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </template>
                                      </v-dialog>
                                    </v-list-item>
                                    <v-dialog transition="dialog-bottom-transition" max-width="400">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-list-item
                                          v-bind="attrs"
                                          v-on="on"
                                          color="EoleError"
                                          link
                                          @click="archiveContact(call.idContact)"
                                        >
                                          <v-list-item-title> Demande traitée</v-list-item-title>
                                          <v-list-item-actions>
                                            <i class="fa-solid fa-circle-check ml-2 EoleGreen--text"></i>
                                          </v-list-item-actions>
                                        </v-list-item>
                                      </template>
                                      <template v-slot:default="dialog">
                                        <v-card>
                                          <v-card-text class="text-center" v-if="contact.length - 1 > 0">
                                            <v-img
                                              src="https://anavel.bzh/assets/success.gif"
                                              max-width="126px"
                                              class="mx-auto"
                                            ></v-img>
                                            Votre demande à été traitée, il ne vous reste plus que
                                            <strong>{{ contact.length - 1 }} appels</strong> !
                                          </v-card-text>
                                          <v-card-text v-else> Vous n'avez plus aucun appel à traiter ! </v-card-text>
                                          <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Fermer</v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </template>
                                    </v-dialog>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-card-text class="flex-grow-1">
                              <v-row class="flex-column">
                                <v-col v-if="call.attributorName === null">
                                  <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                                </v-col>
                                <v-col v-else class="text-left"
                                  >Par {{ call.attributorName.toUpperCase() }} <br />
                                  le {{ call.datetime }}</v-col
                                >
                                <v-col class="text-right">
                                  <v-chip color="EoleYellow" small>Agence de {{ call.agency }}</v-chip>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    :id="`identity-${index}`"
                                    outlined
                                    label="Identité"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-account"
                                    :value="call.lastname + ' ' + call.firstname"
                                    @click:append="copyClipboard(`identity-${index}`)"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="8">
                                  <v-text-field
                                    dense
                                    :id="`email-${index}`"
                                    outlined
                                    label="Email"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-email-outline"
                                    :value="call.email"
                                    @click:append="copyClipboard(`email-${index}`)"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-btn
                                    :disabled="call.email === null"
                                    class="white--text"
                                    :href="`mailto:${call.email}`"
                                    color="EoleBlue"
                                    >Contacter</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="7">
                                  <v-text-field
                                    :id="`phone-${index}`"
                                    outlined
                                    label="Numéro de téléphone"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-phone"
                                    :value="call.phoneNumber"
                                    @click:append="copyClipboard(`phone-${index}`)"
                                  ></v-text-field>
                                </v-col>
                                <v-col class="mt-2" cols="12" md="2">
                                  <v-btn class="white--text" :href="`tel:${call.phoneNumber}`" color="EoleBlue"
                                    >Appeler</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    :id="`idGRC-${index}`"
                                    outlined
                                    label="Identifiant GRC"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-identifier"
                                    :value="call.idGRC"
                                    @click:append="copyClipboard(`idGRC-${index}`)"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-textarea
                                    :id="`reason-${index}`"
                                    no-resize
                                    type="code"
                                    outlined
                                    label="Motif de l'appel"
                                    readonly
                                    :value="call.reason"
                                    rows="1"
                                    auto-grow
                                    append-icon="mdi-content-copy"
                                    @click:append="copyClipboard(`reason-${index}`)"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :value="'tab-2'">
                      <v-row v-if="contactTreated.length === 0" class="text-left pa-5 justify-center">
                        <v-alert type="error" prominent dense text>Il n'y a aucun historique à ce jours.</v-alert>
                      </v-row>
                      <v-row class="pa-5">
                        <v-row v-if="contactTreated.length > 0">
                          <loading
                            transition="fade"
                            :active.sync="waitTimeoutContact"
                            :can-cancel="false"
                            :is-full-page="false"
                            loader="spinner"
                            color="#efb639"
                            :opacity="1"
                          >
                          </loading>
                          <v-col cols="12">
                            <v-data-table
                              item-key="idContact"
                              class="elevation-1"
                              :loading="loading"
                              loading-text="Chargement des produits en cours... Merci de patienter"
                              :page.sync="page"
                              :items-per-page="10"
                              :headers="headersContactTreated"
                              :items="contactTreated"
                              hide-default-footer
                              @page-count="pageCount = $event"
                              sort-by="calories"
                              :footer-props="{
                                'items-per-page-text': 'produits par page(s)',
                              }"
                            >
                              <template v-slot:item.agency="{ item }">
                                <v-chip small color="EoleYellow"> Agence de {{ item.agency }} </v-chip>
                              </template>

                              <template v-slot:item.firstname="{ item }">
                                {{ item.lastname }} {{ item.firstname }}
                              </template>

                              <template v-slot:item.actions="{ item }">
                                <v-dialog max-width="1200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" rounded icon fab>
                                      <i class="fa-solid fa-eye"></i>
                                    </v-btn>
                                  </template>
                                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                    <v-card-text style="background-color: #f1f4f9">
                                      <v-row>
                                        <v-col cols="12" md="3" class="no-padding ml-n2">
                                          <v-card
                                            class="rounded-l-xl"
                                            color="#f1f4f9"
                                            style="background-color: #f1f4f9"
                                            flat
                                          >
                                            <v-card-title>
                                              <v-img
                                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                                max-height="150px"
                                                max-width="120px"
                                                alt="logo"
                                                contain
                                              ></v-img>
                                            </v-card-title>
                                            <v-card-title class="text-center justify-center muller-capitalized"
                                              >Mes Services Assurance</v-card-title
                                            >
                                            <v-card-subtitle class="text-center justify-center"
                                              >Un logiciel ANAVEL</v-card-subtitle
                                            >
                                            <v-card-text class="no-padding">
                                              <v-img
                                                :src="require('@/assets/images/logos/layer.png')"
                                                alt="logo"
                                                width="300px"
                                                class=""
                                              >
                                              </v-img>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="9" class="no-padding d-flex">
                                          <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                            <v-card-title>
                                              <span class="text-h5"
                                                >Votre demande portant le N°{{ item.idContact }}</span
                                              >
                                            </v-card-title>
                                            <v-card-text class="flex-grow-1">
                                              <v-row class="flex-column">
                                                <v-col class="text-left">Demande créée le {{ item.datetime }} </v-col>
                                                <v-col class="text-right">
                                                  <v-chip color="EoleYellow" small>Agence de {{ item.agency }}</v-chip>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="identity"
                                                    outlined
                                                    label="Identité"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-account"
                                                    :value="item.lastname + ' ' + item.firstname"
                                                    @click:append="copyClipboard('identity')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="email"
                                                    outlined
                                                    label="Email"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-email"
                                                    :value="item.email"
                                                    @click:append="copyClipboard('email')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col cols="12" md="7">
                                                  <v-text-field
                                                    dense
                                                    id="phone"
                                                    outlined
                                                    label="Numéro de téléphone"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-phone"
                                                    :value="item.phoneNumber"
                                                    @click:append="copyClipboard('phone')"
                                                  ></v-text-field>
                                                </v-col>
                                                <v-col class="mt-2" cols="12" md="2">
                                                  <v-btn
                                                    class="white--text"
                                                    :href="`tel:${item.phoneNumber}`"
                                                    color="EoleBlue"
                                                    >Appeler</v-btn
                                                  >
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="idGRC"
                                                    outlined
                                                    label="Identifiant GRC"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-identifier"
                                                    :value="item.idGRC"
                                                    @click:append="copyClipboard('idGRC')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-textarea
                                                    dense
                                                    id="reason"
                                                    no-resize
                                                    type="code"
                                                    outlined
                                                    label="Motif de l'appel"
                                                    readonly
                                                    :value="item.reason"
                                                    rows="1"
                                                    auto-grow
                                                    append-icon="mdi-content-copy"
                                                    @click:append="copyClipboard('reason')"
                                                  ></v-textarea>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                            <v-spacer></v-spacer>
                                            <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-dialog>
                              </template>

                              <template v-slot:no-data>
                                <v-alert color="error" text class="ma-2">
                                  <div class="d-flex align-start">
                                    <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                                    <div class="ms-3">
                                      <p class="text-base font-weight-medium mb-1">
                                        Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                      </p>
                                    </div>
                                  </div>
                                </v-alert>
                                <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()">Réessayer</v-btn>
                              </template>
                            </v-data-table>
                            <div class="text-center pt-2">
                              <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </div>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
              <loading
                transition="fade"
                :active.sync="waitTimeoutContact"
                :can-cancel="false"
                :is-full-page="false"
                loader="spinner"
                color="#efb639"
                :opacity="1"
              >
              </loading>

              <!-- <v-card outlined class="text-center">
                <v-card-title class="EoleBlueLighten mb-5">
                  <v-row>
                    <v-col class="text-left" cols="12" md="9">Appels pour {{ selected.completName }}</v-col>
                  </v-row>
                  <v-tabs
                    v-show="!waitTimeoutContact"
                    class="mt-5"
                    v-model="tab"
                    icons-and-text
                    style="box-shadow: none !important"
                    background-color="EoleBlueLighten"
                  >
                    <v-tab href="#tab-1">
                      Demande en attenteefzf
                      <v-icon>mdi-phone</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2">
                      Historique
                      <v-icon>mdi-calendar-refresh</v-icon>
                    </v-tab>
                  </v-tabs>
                </v-card-title>

                <v-tabs-items v-model="tab">
                  <v-tab-item :value="'tab-1'">
                    {{ selected }}
                    <v-row v-if="selected.contactNumber === 0" class="text-left pa-5 justify-center">
                      <v-alert type="error" prominent dense text
                        >Il n'y a aucune demande de rappel pour {{ selected.completName }}</v-alert
                      >
                    </v-row>
                    <v-row class="pa-5">
                      <v-col cols="12" md="6" v-for="(call, index) in contact" :key="index">
                        <v-card outlined elevation="3" class="text-center flex d-flex flex-column rounded-xl">
                          <v-row class="pa-5">
                            <v-col class="text-left" cols="12" md="9"></v-col>
                            <v-col class="text-right" cols="12" md="3">
                              <v-menu right transition="slide-x-transition" close-on-content-click>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>

                                <v-list>
                                  <v-list-item link color="EoleBlue" :disabled="transferInProgress">
                                    <v-dialog transition="dialog-bottom-transition" max-width="600">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-title v-bind="attrs" v-on="on">
                                          Assigner à nouveau
                                        </v-list-item-title>
                                        <v-list-item-actions>
                                          <i class="fa-solid fa-share-from-square ml-2"></i>
                                        </v-list-item-actions>
                                      </template>
                                      <template v-slot:default="dialogContact">
                                        <v-card :loading="transferInProgress">
                                          <v-card-text class="no-padding">
                                            <v-list dense :disabled="transferInProgress">
                                              <v-list-item
                                                :disabled="transferInProgress"
                                                color="EoleBlue"
                                                v-for="(collaborator, index) in collaboratorsAvailable"
                                                :key="index"
                                                link
                                                @click="
                                                  transferContact(
                                                    call.idContact,
                                                    collaborator.id,
                                                    collaborator.completName,
                                                    collaborator.email,
                                                  )
                                                "
                                              >
                                                <v-list-item-avatar color="EoleBlue">
                                                  <v-img
                                                    v-if="collaborator.avatarUrl != null"
                                                    :src="collaborator.avatarUrl"
                                                  ></v-img>
                                                  <span v-else class="white--text ml-3">{{
                                                    collaborator.firstname[0] + collaborator.lastname[0]
                                                  }}</span>
                                                </v-list-item-avatar>
                                                <v-list-item-title>{{ collaborator.completName }} </v-list-item-title>
                                              </v-list-item>
                                            </v-list>
                                          </v-card-text>
                                          <v-card-actions class="justify-end">
                                            <v-btn text @click="dialogContact.value = false">Fermer</v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </template>
                                    </v-dialog>
                                  </v-list-item>
                                  <v-dialog transition="dialog-bottom-transition" max-width="400">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-list-item
                                        v-bind="attrs"
                                        v-on="on"
                                        color="EoleError"
                                        link
                                        @click="archiveContact(call.idContact)"
                                      >
                                        <v-list-item-title> Demande traitée</v-list-item-title>
                                        <v-list-item-actions>
                                          <i class="fa-solid fa-circle-check ml-2 EoleGreen--text"></i>
                                        </v-list-item-actions>
                                      </v-list-item>
                                    </template>
                                    <template v-slot:default="dialog">
                                      <v-card>
                                        <v-card-text class="text-center" v-if="contact.length - 1 > 0">
                                          <v-img
                                            src="https://anavel.bzh/assets/success.gif"
                                            max-width="126px"
                                            class="mx-auto"
                                          ></v-img>
                                          Votre demande à été traitée, il ne vous reste plus que
                                          <strong>{{ contact.length - 1 }} appels</strong> !
                                        </v-card-text>
                                        <v-card-text v-else> Vous n'avez plus aucun appel à traiter ! </v-card-text>
                                        <v-card-actions class="justify-end">
                                          <v-btn text @click="dialog.value = false">Fermer</v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </template>
                                  </v-dialog>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-card-text class="flex-grow-1">
                            <v-row class="flex-column">
                              <v-col v-if="call.attributorName === null">
                                <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                              </v-col>
                              <v-col v-else class="text-left"
                                >Par {{ call.attributorName.toUpperCase() }} <br />
                                le {{ call.datetime }}</v-col
                              >
                              <v-col class="text-right">
                                <v-chip color="EoleYellow" small>Agence de {{ call.agency }}</v-chip>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  :id="`identity-${index}`"
                                  outlined
                                  label="Identité"
                                  append-icon="mdi-content-copy"
                                  readonly
                                  prepend-inner-icon="mdi-account"
                                  :value="call.lastname + ' ' + call.firstname"
                                  @click:append="copyClipboard(`identity-${index}`)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="8">
                                <v-text-field
                                  dense
                                  :id="`email-${index}`"
                                  outlined
                                  label="Email"
                                  append-icon="mdi-content-copy"
                                  readonly
                                  prepend-inner-icon="mdi-email-outline"
                                  :value="call.email"
                                  @click:append="copyClipboard(`email-${index}`)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-btn
                                  :disabled="call.email === null"
                                  class="white--text"
                                  :href="`mailto:${call.email}`"
                                  color="EoleBlue"
                                  >Contacter</v-btn
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="7">
                                <v-text-field
                                  :id="`phone-${index}`"
                                  outlined
                                  label="Numéro de téléphone"
                                  append-icon="mdi-content-copy"
                                  readonly
                                  prepend-inner-icon="mdi-phone"
                                  :value="call.phoneNumber"
                                  @click:append="copyClipboard(`phone-${index}`)"
                                ></v-text-field>
                              </v-col>
                              <v-col class="mt-2" cols="12" md="2">
                                <v-btn class="white--text" :href="`tel:${call.phoneNumber}`" color="EoleBlue"
                                  >Appeler</v-btn
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  :id="`idGRC-${index}`"
                                  outlined
                                  label="Identifiant GRC"
                                  append-icon="mdi-content-copy"
                                  readonly
                                  prepend-inner-icon="mdi-identifier"
                                  :value="call.idGRC"
                                  @click:append="copyClipboard(`idGRC-${index}`)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-textarea
                                  :id="`reason-${index}`"
                                  no-resize
                                  type="code"
                                  outlined
                                  label="Motif de l'appel"
                                  readonly
                                  :value="call.reason"
                                  rows="1"
                                  auto-grow
                                  append-icon="mdi-content-copy"
                                  @click:append="copyClipboard(`reason-${index}`)"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item :value="'tab-2'">
                    <v-row v-if="contactTreated.length === 0" class="text-left pa-5 justify-center">
                      <v-alert type="error" prominent dense text>Il n'y a aucun historique à ce jours.</v-alert>
                    </v-row>
                    <v-row class="pa-5">
                      <v-row v-if="contactTreated.length > 0">
                        <loading
                          transition="fade"
                          :active.sync="waitTimeoutContact"
                          :can-cancel="false"
                          :is-full-page="false"
                          loader="spinner"
                          color="#efb639"
                          :opacity="1"
                        >
                        </loading>
                        <v-col cols="12">
                          <v-data-table
                            item-key="idContact"
                            class="elevation-1"
                            :loading="loading"
                            loading-text="Chargement des produits en cours... Merci de patienter"
                            :page.sync="page"
                            :items-per-page="10"
                            :headers="headersContactTreated"
                            :items="contactTreated"
                            hide-default-footer
                            @page-count="pageCount = $event"
                            :single-expand="singleExpand"
                            :expanded.sync="expanded"
                            show-expand
                            sort-by="calories"
                            :footer-props="{
                              'items-per-page-text': 'produits par page(s)',
                            }"
                          >
                            <template v-slot:item.agency="{ item }">
                              <v-chip small color="EoleYellow"> Agence de {{ item.agency }} </v-chip>
                            </template>

                            <template v-slot:item.firstname="{ item }">
                              {{ item.lastname }} {{ item.firstname }}
                            </template>

                            <template v-slot:no-data>
                              <v-alert color="error" text class="ma-2">
                                <div class="d-flex align-start">
                                  <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                                  <div class="ms-3">
                                    <p class="text-base font-weight-medium mb-1">
                                      Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                    </p>
                                  </div>
                                </div>
                              </v-alert>
                              <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()">Réessayer</v-btn>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                              <td class="pa-5" :colspan="headers.length">
                                <v-row class="my-2">
                                  <v-col class="d-flex" cols="12">
                                    <v-card outlined elevation="2" class="text-center flex d-flex flex-column" disabled>
                                      <v-card-text class="flex-grow-1">
                                        <v-row class="flex-column">
                                          <v-col class="text-left">Demande créée le {{ item.datetime }}</v-col>
                                          <v-col class="text-right">
                                            <v-chip color="EoleYellow" small>Agence de {{ item.agency }}</v-chip>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              id="identity"
                                              outlined
                                              label="Identité"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-account"
                                              :value="item.lastname + ' ' + item.firstname"
                                              @click:append="copyClipboard('identity')"
                                            >
                                            </v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" md="7">
                                            <v-text-field
                                              id="phone"
                                              outlined
                                              label="Numéro de téléphone"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-phone"
                                              :value="item.phoneNumber"
                                              @click:append="copyClipboard('phone')"
                                            >
                                            </v-text-field>
                                          </v-col>
                                          <v-col class="mt-2" cols="12" md="2">
                                            <v-btn
                                              class="white--text"
                                              :href="`tel:${item.phoneNumber}`"
                                              color="EoleBlue"
                                            >
                                              Appeler</v-btn
                                            >
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              id="idGRC"
                                              outlined
                                              label="Identifiant GRC"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-identifier"
                                              :value="item.idGRC"
                                              @click:append="copyClipboard('idGRC')"
                                            >
                                            </v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-textarea
                                              id="reason"
                                              no-resize
                                              type="code"
                                              outlined
                                              label="Motif de l'appel"
                                              readonly
                                              :value="item.reason"
                                              rows="1"
                                              auto-grow
                                              append-icon="mdi-content-copy"
                                              @click:append="copyClipboard('reason')"
                                            ></v-textarea>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </td>
                            </template>
                          </v-data-table>
                          <div class="text-center pt-2">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                          </div>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card> -->
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </div>
  </section>
</template>
<script>
import Vue from "vue"
import VueConfetti from "vue-confetti"
import config from "@/views/config/config.js"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"
moment.locale("fr")
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import io from "socket.io-client"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
Vue.use(VueConfetti)
// import Snowf from "vue-snowf"
import { sendNotification, socket } from "@/utils/socket.js"
import VueApexCharts from "vue-apexcharts"

export default {
  components: {
    Loading,
    CustomeLoader,
    apexchart: VueApexCharts,
  },
  data: () => ({
    monthlyRequestsServicesChartOptions: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: [], // Les noms des mois seront ajoutés ici
      },
      yaxis: {
        title: {
          text: "Nombre de demandes",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " demandes"
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },

    monthlyRequestsServicesSeries: [],
    monthlyRequestsSeries: [],
    monthlyRequestsChartOptions: {},
    pieChartOptions: {},
    pieSeries: [],
    sendSeries: [],
    sendChartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },
    annualSendSeries: [],
    annualSendChartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },
    annualSeries: [],
    annualChartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },
    statistics: [],
    series: [],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },
    dialogTransferContact: false,
    avatarUrlCollaborator: null,
    firstnameCollaborator: localStorage.getItem("firstname"),
    lastnameCollaborator: localStorage.getItem("lastname"),
    avatarUrl: null,
    searchContactTreated: "",
    dialogArchiveContact: false,
    dialogContact: false,
    transferInProgress: false,
    clientTypologyProposed: [
      {
        name: "PROFESSIONNEL",
        value: 2,
        icon: "fa-building",
        e1: 2,
        hovered: false,
      },
      {
        name: "PARTICULIER",
        value: 1,
        icon: "fa-solid fa-people-arrows ",
        e1: 2,
        hovered: false,
      },
    ],
    e1: 1,
    collaboratorName: null,
    clientTypologie: null,
    singleExpand: true,
    expanded: [],
    loading: false,
    page: 1,
    pageCount: 0,
    headersContactTreated: [
      {
        text: "Agence",
        align: "start",
        value: "agency",
      },
      {
        text: "Date d'enregistrement",
        value: "datetime",
      },
      {
        text: "Identité client",
        value: "firstname",
      },
      {
        text: "Contact",
        value: "phoneNumber",
      },
      {
        text: "Consulter la demande",
        value: "actions",
        align: "center",
      },
    ],
    contactTotalCount: 0,
    tab: null,
    open: [],
    time: "",
    genderRules: [v => !!v || "Un genre est requis."],
    firstnameRules: [v => !!v || "Un prénom est requis."],
    lastnameRules: [v => !!v || "Un nom est requis."],
    agencyRules: [v => !!v || "Une agence est requise."],
    collaboratorRules: [v => !!v || "Un collaborateur à attribuer est requis."],
    reasonRules: [v => !!v || "Un motif est requis."],
    phoneRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    validityFormContact: false,
    validityFormContactPro: false,
    waitTimeout: false,
    waitTimeoutContact: false,
    collaboratorsAvailable: [],
    grade: null,
    dialog: false,
    selection: [],
    idSelect: -1,
    nb: 0,
    active: [],
    // users
    emailSent: {},
    statistics: [],
    contact: [],

    collaborators: [],
    collaboratorsTreated: [],
    contactTreated: [],

    collaborator: [],

    idLast: -1,
    editedIndex: -1,
    editedItem: {
      id: null,
      name: "",
      gender: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: null,
      idGRC: null,
      agence: null,
      reason: null,
    },
    //Pour mettre vide faire avec editedDefault
    pageContact: 1,
  }),
  mounted() {
    const userName = localStorage.getItem("complete_name")

    socket.on("notification", data => {
      this.fetchUsers()
      this.fetchUserContactTreated(localStorage.getItem("user_id"))
    })
  },
  computed: {
    paginatedContact() {
      const start = (this.pageContact - 1) * 3
      const end = start + 3
      return this.contact.slice(start, end)
    },
    pageContactCount() {
      return Math.ceil(this.contact.length / 3)
    },
    items() {
      return [
        {
          id: 1,
          name: `Demandes de rappels`,
          children: this.collaborators,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      this.getImg(
        this.collaborators.find(user => user.id === id).lastname,
        this.collaborators.find(user => user.id === id).firstname,
      )

      this.contact = []
      this.fetchUserContact(id)

      this.fetchUserContactTreated(id)

      return this.collaborators.find(collaborators => collaborators.id === id)
    },
  },
  async created() {
    this.collaboratorName = localStorage.getItem("firstname")
    this.grade = localStorage.getItem("grade")

    if (this.grade === "collaborateur") {
      this.fetchAccountCounter()
      this.fetchUsers()
      this.fetchUserContactTreated(localStorage.getItem("user_id"))
      this.getImgCollaborator(localStorage.getItem("lastname"), localStorage.getItem("firstname"))
    }
  },

  methods: {
    async updateStackedBarChartData(monthlyData) {
      console.log(monthlyData)
      // Définir les catégories de l'axe X avec les noms des mois
      this.monthlyRequestsServicesChartOptions.xaxis.categories = monthlyData.map(data => data.month_name)

      // Initialiser un objet pour collecter les séries par service
      let seriesMap = {}

      // Préparer un tableau de données initial contenant 0 pour chaque mois
      const initialData = new Array(monthlyData.length).fill(0)

      monthlyData.forEach((month, index) => {
        month.services.forEach(service => {
          if (!seriesMap[service.service_name]) {
            // Utiliser une copie du tableau initialData pour chaque nouveau service
            seriesMap[service.service_name] = { name: service.service_name, data: [...initialData] }
          }
          // Remplacer 0 par le total_requests pour le mois concerné
          seriesMap[service.service_name].data[index] = service.total_requests
        })
      })

      // Convertir l'objet des séries en tableau
      this.monthlyRequestsServicesSeries = Object.values(seriesMap)
    },
    async updateMonthlyCallbackRequestsChart(monthlyCallbackRequests) {
      // Extraction des labels (noms des mois) et des valeurs (total des demandes)
      const labels = monthlyCallbackRequests.map(item => item.month_name)
      const seriesData = monthlyCallbackRequests.map(item => item.total_requests)

      // Mise à jour des options du graphique et des séries de données
      this.monthlyRequestsChartOptions = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: labels,
        },
        yaxis: {
          title: {
            name: "Demandes de rappel mensuelles",
          },
        },
        // title: {
        //   text: "Monthly Callback Requests",
        //   align: "center",
        // },
        colors: ["#00E396"], // Couleur des barres
      }

      this.monthlyRequestsSeries = [
        {
          name: "Demandes de rappel mensuelles",
          data: seriesData,
        },
      ]
    },
    async updatePieChartData(requestsDistribution) {
      // Filtrer pour exclure l'élément total_requests
      const filteredData = requestsDistribution.filter(item => item.id_account)

      // Préparer les labels et les valeurs pour le graphique en secteurs
      const labels = filteredData.map(item => `${item.firstname} ${item.lastname}`)
      const series = filteredData.map(item => parseInt(item.request_count, 10))

      // Mettre à jour les données du graphique pour ApexCharts
      this.pieChartOptions = {
        chart: {
          type: "pie",
          height: "100px",
        },
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                height: 200,
              },
              legend: {
                position: "left",
              },
            },
          },
        ],
      }

      this.pieSeries = series

      // Assurez-vous d'avoir `pieChartOptions` et `pieSeries` définis dans la section `data` de votre composant Vue
    },
    async updateChartData(weeklyRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnCours = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact, // Le nombre de demandes en cours pour cette date
      }))

      const dataDemandesTraitees = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact_history, // Le nombre de demandes traitées pour cette date
      }))

      // Mettre à jour les séries de données pour le graphique
      this.series = [
        {
          name: "Demandes en cours",
          data: dataDemandesEnCours,
          color: "#FCC03C", // Rouge, par exemple
          foreColor: "#FF4C51",
        },
        {
          name: "Demandes traitées",
          data: dataDemandesTraitees,
          color: "#001F47", // Vert, par exemple
          foreColor: "#FF4C51",
        },
      ]
    },
    async updateAnnualChartData(annualRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesTraiteesAnnuelles = annualRequests.map(item => ({
        x: item.request_year.toString(), // Convertir l'année en chaîne pour l'affichage
        y: item.request_count_treated, // Le nombre de demandes traitées pour cette année
      }))

      // Mettre à jour les séries de données pour le graphique
      this.annualSeries = [
        {
          name: "Demandes traitées annuellement",
          data: dataDemandesTraiteesAnnuelles,
          color: "#001F47", // Vert, par exemple
        },
      ]
    },
    async updateSendChartData(weeklySendRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnvoyees = weeklySendRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_sent, // Le nombre de demandes envoyées pour cette date
      }))

      // Mettre à jour les séries de données pour le graphique
      this.sendSeries = [
        {
          name: "Demandes envoyées",
          data: dataDemandesEnvoyees,
          color: "#9D9D9C", // Rouge, par exemple
        },
      ]
    },
    async updateAnnualSendChartData(annualSendRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnvoyeesAnnuelles = annualSendRequests.map(item => ({
        x: item.request_year.toString(), // Convertir l'année en chaîne pour l'affichage
        y: item.request_count_sent, // Le nombre de demandes envoyées pour cette année
      }))

      // Mettre à jour les séries de données pour le graphique
      this.annualSendSeries = [
        {
          name: "Demandes envoyées annuellement",
          data: dataDemandesEnvoyeesAnnuelles,
          color: "#9D9D9C", // Rouge, par exemple
        },
      ]
    },
    async fetchAccountCounter() {
      this.account = []

      let headers = new Headers()
      let idAccount = localStorage.getItem("user_id")

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/accounts/${idAccount}/counter`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          this.updateChartData(data.account[0].weekly_requests)
          this.updateAnnualChartData(data.account[0].annual_treated_requests)
          this.updateSendChartData(data.account[0].weekly_send_requests)
          this.updateAnnualSendChartData(data.account[0].annual_send_requests)
          this.updatePieChartData(data.account[0].requests_distribution)
          this.updateMonthlyCallbackRequestsChart(data.account[0].monthly_callback_requests)
          this.updateStackedBarChartData(data.account[0].monthly_callback_requests_services)
        }
      } catch (e) {
        console.log(e)
      }
    },
    onPaste() {
      return (this.editedItem.phoneNumber = this.editedItem.phoneNumber.replace(/ /g, ""))
    },
    test() {
      alert("GED POUR :")
    },

    func() {
      this.collaborators.forEach(el => {
        if (el.id === this.editedItem.id) {
          this.emailSent = {
            firstname: el.firstname,
            lastname: el.lastname,
            mail: el.email,
          }
        }
      })
    },
    sendmail() {
      console.log("EditedItem", this.editedItem)
      console.log("emailSent", this.emailSent)
      try {
        emailjs.send("service_5p49t7p", "template_mv9abd5", {
          to_name: this.emailSent.firstname,
          //from_name: email,
          to_email: this.emailSent.mail,
          to_client: this.editedItem.lastName,
          receiver_name: this.emailSent.firstname,
          to_gender: this.editedItem.gender,
          sender_name: this.collaboratorName,
          to_client_mail: this.editedItem.email,
        })
      } catch (error) {
        console.log(error)
      }
    },
    sendMailClient() {
      console.log(this.editedItem)
      if (this.clientTypologie === 1) {
        try {
          emailjs.send("service_5p49t7p", "template_lxbkpd9", {
            to_name: this.editedItem.lastName,
            receiver_name: this.emailSent.firstname,
            to_email: this.editedItem.email,
            to_gender: this.editedItem.gender,
            sender_name: this.collaboratorName,
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          emailjs.send("service_5p49t7p", "template_lxbkpd9", {
            to_name: this.editedItem.lastName,
            receiver_name: this.emailSent.firstname,
            to_email: this.editedItem.email,
            to_gender: this.editedItem.gender,
            sender_name: this.collaboratorName,
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    async transferContact(idContact, newIdAccount, name, email) {
      this.transferInProgress = true

      if (newIdAccount != localStorage.getItem("user_id")) {
        try {
          let headers = new Headers()
          headers.append("Content-Type", "application/json charset=UTF-8")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")
          let response = await fetch(
            `${config.apiUri}/php/pages/client_contact.php?idContact=${idContact}&transferContact=true&newIdAccount=${newIdAccount}`,
            {
              mode: "cors",
              method: "PUT",
              headers: headers,
            },
          ).then(response => {
            if (response.ok) {
              emailjs
                .send("service_5p49t7p", "template_3dsezmr", {
                  to_name: name,
                  to_email: email,
                })
                .then(response => {
                  if (response.status === 200) {
                    this.$toast.success(`Vous avez transféré cet appel.`, {
                      position: "bottom-right",
                      timeout: 5000,
                    })
                    this.dialogContact = false

                    this.transferInProgress = false
                    let index = this.contact.map(ob => ob.idContact).indexOf(idContact)
                    this.contact.splice(index, 1)

                    this.dialogTransferContact = false
                    sendNotification(
                      newIdAccount.toString(),
                      1,
                      "Nouvelle demande de rappel",
                      "Vous venez de recevoir un transfert de demande de rappel.",
                    )
                  } else {
                    this.$toast.error(`Une erreur est survenue.\nImpossible de transfèrer l'appel`, {
                      position: "bottom-right",
                      timeout: 5000,
                    })
                  }
                })
            }
          })

          //
        } catch (e) {
          this.$toast.error(`Une erreur est survenue.\nImpossible de transfèrer l'appel`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } else {
        this.$toast.error(`Vous ne pouvez pas vous transférez l'appel à vous même`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    async archiveContact(idContact) {
      this.$confetti.start({
        // september autumn
        // particles: [
        //   {
        //     type: "image",
        //     size: 24,
        //     url: "https://anavel.bzh/assets/september/leaf1.png",
        //   },
        //   {
        //     type: "image",
        //     size: 24,
        //     url: "https://anavel.bzh/assets/september/leaf2.png",
        //   },
        //   {
        //     type: "image",
        //     size: 24,
        //     url: "https://anavel.bzh/assets/september/leaf3.png",
        //   },
        // ],
        // October halloween
        // particles: [
        //   {
        //     type: "image",
        //     size: 24,
        //     url: "https://anavel.bzh/assets/october/potion.png",
        //   },
        //   {
        //     type: "image",
        //     size: 24,
        //     url: "https://anavel.bzh/assets/october/vampire.png",
        //   },
        //   {
        //     type: "image",
        //     size: 24,
        //     url: "https://anavel.bzh/assets/october/citrouille.png",
        //   },
        //   {
        //     type: "image",
        //     size: 24,
        //     url: "https://anavel.bzh/assets/october/bat.png",
        //   },
        // ],
      })

      setTimeout(() => {
        this.$confetti.stop()
      }, 4000)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(`${config.apiUri}/contact/${idContact}`, {
          mode: "cors",
          method: "PUT",
          headers: headers,
        })
        this.$toast.success(`Vous avez traité cet appel.`, {
          position: "bottom-right",
          timeout: 5000,
        })

        setTimeout(() => {
          let indexContact = this.contact.map(ob => ob.idContact).indexOf(idContact)
          this.contact.splice(indexContact, 1)
        }, 6000)
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    validateFormContact(itemId) {
      if (this.clientTypologie === 1) {
        this.validityFormContact = true
        if (this.$refs.formContact.validate()) {
          this.save(itemId)
        }
      } else {
        this.validityFormContactPro = true
        if (this.$refs.formContactPro.validate()) {
          this.save(itemId)
        }
      }
    },

    copyClipboard(idElement) {
      let copyText = document.getElementById(idElement)

      try {
        copyText.select()
        navigator.clipboard.writeText(copyText.value)

        this.$toast.success(`Le texte a été copié dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 2000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de copier le texte dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 2000,
        })
      }
    },

    async fetchAttributorById(index, idAttributor) {
      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")

        const response = await fetch(`${config.apiUri}/accounts/${idAttributor}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(element => {
          this.contact[index].attributorName = element.lastname + " " + element.firstname
        })
      } catch (e) {
        console.log(e)
      }
    },
    async fetchUserContact(idCollaborator) {
      this.waitTimeoutContact = true
      this.contact = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")

        const responseContact = await fetch(`${config.apiUri}/accounts/${idCollaborator}/contact`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const dataContact = await responseContact.json()

        dataContact.forEach(element => {
          this.contact.push({
            id: element.id_account,
            idContact: element.id,
            lastname: element.lastname,
            firstname: element.firstname,
            phoneNumber: element.phone_number,
            idGRC: element.idGRC,
            agency: element.agency,
            reason: element.reason,
            attributorName: null,
            datetime: moment(element.date_time).format("dddd Do MMM à HH:mm"),
            email: element.email,
          })

          this.fetchAttributorById(this.contact.map(ob => ob.idContact).indexOf(element.id), element.id_attributor)
        })

        this.collaborators.forEach(collaborator => {
          if (collaborator.id === idCollaborator) {
            collaborator.contactNumber = dataContact.length
            this.contactTotalCount += dataContact.length
          }
        })

        this.waitTimeoutContact = false
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserContactTreated(idCollaborator) {
      this.contactTreated = []
      this.loading = true

      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")

        const responseContact = await fetch(`${config.apiUri}/accounts/${idCollaborator}/contact/history`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const dataContact = await responseContact.json()

        dataContact.forEach(element => {
          this.contactTreated.push({
            id: element.id_account,
            idContact: element.id,
            lastname: element.lastname,
            firstname: element.firstname,
            phoneNumber: element.phone_number,
            idGRC: element.idGRC,
            agency: element.agency,
            reason: element.reason,
            attributorName: null,
            datetime: moment(element.date_time).format("dddd Do MMM à HH:mm"),
            email: element.email,
          })

          // this.fetchAttributorById(
          //   this.contactTreated.map(ob => ob.idContact).indexOf(element.id),
          //   element.id_attributor,
          // )
        })

        this.collaboratorsTreated.forEach(collaborator => {
          if (collaborator.id === idCollaborator) {
            collaborator.contactNumber = dataContact.length
          }
        })
        this.loading = false
      } catch (e) {
        console.log(e)
      }
    }, // get image in folder asset for avatar and display error if not found
    getImgCollaborator(lastname, firstname) {
      this.imgError = false

      try {
        let name = `${lastname.toLowerCase()}-${firstname.toLowerCase()}`
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.avatarUrlCollaborator = img.src
        }
        img.onerror = () => {
          this.imgError = true
          this.avatarUrlCollaborator = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.avatarUrlCollaborator == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.imgError = true
        this.avatarUrlCollaborator == null
      }
    },
    getImg(lastname, firstname) {
      this.imgError = false

      try {
        let name = `${lastname.toLowerCase()}-${firstname.toLowerCase()}`
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.avatarUrl = img.src
        }
        img.onerror = () => {
          this.imgError = true
          this.avatarUrl = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.avatarUrl == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.imgError = true
        this.avatarUrl == null
      }
    },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    async fetchUsers() {
      this.collaborators = []
      this.collaborator = []
      this.collaboratorsAvailable = []
      this.waitTimeout = true

      await pause(1500)

      try {
        let headers = new Headers()
        let userId = localStorage.getItem("user_id")

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let masterAccountId = localStorage.getItem("master_account_linked")

        localStorage.getItem("master_account_linked") != null
          ? (masterAccountId = localStorage.getItem("master_account_linked"))
          : (masterAccountId = localStorage.getItem("user_id"))

        const response = await fetch(`${config.apiUri}/users/${masterAccountId}/account`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        await Promise.all(
          data.map(async (element, index) => {
            const avatarUrl = await this.getImg2(element.lastname, element.firstname)

            if (element.available == 1 && element.disabled == 0) {
              if (element.id != userId) {
                this.collaboratorsAvailable.push({
                  id: element.id,
                  lastname: element.lastname,
                  completName: element.lastname + " " + element.firstname,
                  firstname: element.firstname,
                  email: element.email,
                  contactNumber: 0,
                  avatarUrl: avatarUrl,
                  contact_number: parseInt(element.number_contact),
                })
              }
            }

            if (element.disabled == 0) {
              this.collaborators.push({
                id: element.id,
                lastname: element.lastname,
                completName: element.lastname + " " + element.firstname,
                firstname: element.firstname,
                email: element.email,
                contactNumber: 0,
                avatarUrl: avatarUrl,
              })
              this.collaboratorsTreated.push({
                id: element.id,
                lastname: element.lastname,
                completName: element.lastname + " " + element.firstname,
                email: element.email,
                contactNumber: 0,
                avatarUrl: avatarUrl,
              })
            }
          }),
        )

        this.collaborators.forEach((collaborator, index) => {
          if (collaborator.id == userId && this.grade === "collaborateur") {
            this.collaborators.splice(index, 1)
          }
        })

        if (this.grade === "administrateur" || this.grade === "superadministrateur") {
          this.collaborators.forEach(c => {
            this.fetchUserContact(c.id)
          })
        } else {
          this.collaborator = {
            firstname: localStorage.getItem("firstname"),
            lastname: localStorage.getItem("lastname"),
            completName: localStorage.getItem("lastname") + " " + localStorage.getItem("firstname"),
            contactNumber: 0,
          }

          this.fetchUserContact(userId)
        }

        this.waitTimeout = false

        this.setSolicitedStatus()
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    setSolicitedStatus() {
      // Filtrez les collaborateurs avec un contact_number supérieur à 0
      const collaboratorsWithContacts = this.collaboratorsAvailable.filter(c => c.contact_number > 0)

      console.log("collaboratorsWithContacts", collaboratorsWithContacts)

      // Triez le tableau par contact_number décroissant pour ceux avec des contacts
      collaboratorsWithContacts.sort((a, b) => b.contact_number - a.contact_number)

      // Calculez la médiane des contact_number pour les collaborateurs filtrés
      const median = this.calculateMedian(collaboratorsWithContacts.map(c => c.contact_number))

      // Attribuez le statut de sollicitation en fonction de la médiane
      this.collaboratorsAvailable.forEach(collaborator => {
        if (collaborator.contact_number === 0) {
          collaborator.solicitationStatus = 0 // Pas de sollicitation
        } else if (collaborator.contact_number > median) {
          collaborator.solicitationStatus = 1 // Plus sollicité que la médiane
        } else if (collaborator.contact_number === median) {
          collaborator.solicitationStatus = 2 // Égal à la médiane
        } else {
          collaborator.solicitationStatus = 3 // Moins sollicité que la médiane
        }
      })
      console.log("median", median, "collaboratorsWithContacts", collaboratorsWithContacts)
      console.log(this.collaboratorsAvailable)
    },

    calculateMedian(numbers) {
      const sorted = numbers.slice().sort((a, b) => a - b)
      const middle = Math.floor(sorted.length / 2)

      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2
      } else {
        return sorted[middle]
      }
    },

    async post(data) {
      try {
        let headers = new Headers()
        let datetime = require("moment")().format("YYYY-MM-DD HH:mm:ss")

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/client_contact.php?idAccount=${data.id_account}&lastname=${
            data.lastname
          }&firstname=${data.firstname}&phoneNumber=${data.phone_number}&idGRC=${data.idGRC}&agency=${
            data.agency
          }&reason=${encodeURIComponent(data.reason)}&attributor=${
            data.id_attributor
          }&currentDatetime=${datetime}&email=${data.email}`,
          {
            mode: "no-cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          },
        )

        this.$toast.success(
          `Vous avez créer une demande de rappel pour ${this.emailSent.firstname.toUpperCase()} ${this.emailSent.lastname.toUpperCase()}.\nLe client ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} doit être recontacté.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )

        sendNotification(
          data.id_account.toString(),
          1,
          "Nouvelle demande de rappel",
          "Vous avez une nouvelle demande de rappel à traiter.",
        )
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\n${e}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    save(idUser) {
      let find = false
      this.idLast = idUser

      // this.users.push({id: this.idLast,
      //     name: this.editedItem.name,
      //     clientToRecontact : [{
      //       firstName: this.editedItem.firstName,
      //       lastName: this.editedItem.lastName,
      //       phoneNumber: this.editedItem.phoneNumber,
      //       idGRC: this.editedItem.idGRC,
      //       agence: this.editedItem.agence,
      //       reason: this.editedItem.reason
      //     }]
      // })
      this.post({
        id_account: this.idLast,
        firstname: this.editedItem.firstName,
        lastname: this.editedItem.lastName,
        phone_number: this.editedItem.phoneNumber,
        idGRC: this.editedItem.idGRC,
        agency: this.editedItem.agence,
        reason: this.editedItem.reason,
        id_attributor: localStorage.getItem("user_id"),
        email: this.editedItem.email,
      })

      this.sendmail()
      this.sendMailClient()
      this.editedItem.name = null
      this.editedItem.firstName = ""
      this.editedItem.lastName = ""
      this.editedItem.phoneNumber = null
      this.editedItem.idGRC = null
      this.editedItem.agence = null
      this.editedItem.reason = null
      this.editedItem.email = null
      this.editedItem.id = null
      this.editedItem.gender = null

      this.nb = null

      this.close()
    },

    close() {
      this.dialog = false
      this.e1 = 1
    },
  },
}
</script>
<style lang="scss" scoped>
.profilTabs {
  position: absolute;
  top: 32px;
  left: 115px;
}
// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (min-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 45px;
    z-index: 0;
    width: 0%;
  }
}

.profilBannerAccount {
  // background-image: url("../../../../src/assets/images/profils/agence.png");
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}
.contact-study {
  max-width: 450px;
}
.no-padding {
  padding: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .services {
    padding: 1.5rem !important;
  }

  .favorite-card {
    width: 100% !important;
  }
}

.greeting-card {
  position: relative;

  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }

    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
